<template>
  <div>
    <b-row>
      <b-col
        sm="12"
        class="text-right"
      >
      <locale />
      </b-col>
    </b-row>
    <b-row
      class="d-flex justify-content-center"
    >
      <b-col
        sm='12'
        class="text-center pb-1"
      >
      <!-- Brand logo-->
      <b-link>
        <img class="img_logo" :src="logo" alt="logo">
      </b-link>
      <!-- /Brand logo-->
      </b-col>
    </b-row>
    <b-row class="mainrowtxt">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-3"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1 fgtpass">
            {{$t('message.forgot_pass')}} 🔒
          </b-card-title>
          <b-card-text class="mb-2 entrmail">
            {{$t('message.enter_your_email')}}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{$t('message.reset_link')}}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> {{$t('message.back_login')}}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import Locale from '../components/Locale.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    Locale
  },
  data() {
    return {
      logo: require('@/assets/images/quotation/sft_logo.svg'),
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    toast(msg,variantMsg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: msg,
          icon: 'EditIcon',
          variant: variantMsg,
        },
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const data = {
            token:'1dbaa403b94ccdd1991c3afd511858a5',
            email:this.userEmail
          }
          const url= {
            prod: 'https://api-quote.safetravel.today/v2/app/reset_password_link'
          };
          try {
            fetch(url.prod, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
            .then((response)=>response.json())
            .then((res)=> {
              if (res) {
                if (res === 'not_valid') {
                  this.toast('Your search returned no results. Try again with other information.','danger')
                } else if(res==='link_already_sent') {
                  this.toast('Please check your email. A link that has already been sent to reset your password is still active.','success');
                }
                else if (res==='email_sent') {
                  this.userEmail='';
                  this.toast('Please check your email. A link has been sent to reset your password.','success')
                }
              }
            })
          } catch (error) {
            // TypeError: Failed to fetch
            console.warn('There was an error getting email link to reset password', error);
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.img_logo {
  display: block;
  margin: 0 auto;
  max-width: 700px;
  width: 80%;
  height: auto;
}
.fgtpass {
  color: $green;
  font-weight: 700;
}
.entrmail {
  color: $gray-600;
  font-weight: 500;
}
label[for='forgot-password-email'] {
  color:$gray-600;
  font-weight: 600;
}
@media screen and (max-width: 992px) {
  .mainrowtxt {
    margin-top:25px;
  }
}
</style>
